<template>
  <div>
    <div class="user-detail-wraps">
      <el-row :gutter="20">
        <el-col :span="8" class="user-detail-left">
          <el-avatar
              class="avatar"
              :size="150"
              :src="user.avatar || '/user.png'"
            ></el-avatar>
            <div>
              <h3>{{user.name}}</h3>
            </div>
        </el-col>
        <el-col :span="16" class="user-detail-right">
          <h3>我的资料</h3>
          <el-form label-position="right" ref='form' :model="form" :rules="rules" label-width="80px">
              <el-form-item label="姓名" prop='username'>
                <el-input v-model="form.username"></el-input>
              </el-form-item>
              <el-form-item label="性别" prop='sex'>
                <el-radio-group v-model="form.sex">
                  <el-radio :label="1">男</el-radio>
                  <el-radio :label="2">女</el-radio>
                  <el-radio :label="9">未知</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="出生年月"  prop='birthday'>
                <el-date-picker
                    class="w100"
                    v-model="form.birthday"
                    value-format="yyyy-MM-dd"
                    type="date"
                    placeholder="选择日期">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="联系电话" prop='phoneNumber'>
                <el-input v-model="form.phoneNumber" disabled></el-input>
              </el-form-item>
              <el-form-item label="电子邮箱"  prop='email'>
                <el-input v-model="form.email"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="doUpdate">保存</el-button>
              </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      editUserInfo: {},
      form:{
        username:'',
        email:'',
        sex:null,
        birthday:'',
        phoneNumber:''
      },
      rules:{
        username: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          { min: 2, max: 5, message: '长度在 2 到 5 个字符', trigger: ['blur', 'change'] }
        ],
        email:[
          { type: 'email', message: '邮箱格式不正确', trigger: ['blur', 'change'] }
        ],
      },
    }
  },
  computed: {
    user() {
      return this.$store.state.login.userInfo;
    },
  },
  mounted(){
     this.form = {
        username: this.user.name ? this.user.name : this.user.username,
        email: this.user.email,
        sex:this.user.sex,
        birthday: this.user.birthday,
        phoneNumber: this.user.phoneNumber
      }
  },
  methods: {
      //更新
    doUpdate(){
        this.$refs['form'].validate((valid) => {
          if (valid) {
            this.loadingShow("更新中...")
            this.$store.dispatch("login/updateUserInfo", this.form)
              .then( res => {
                if(res == true){
                  this.$message.success("修改资料完成")
                  this.$store.dispatch('login/loadLoginUserInfo').then( res =>{
                     this.$store.dispatch('login/refreshToken')
                  })
                }else{
                  this.$message.error(res.message)
                }
                this.loadingHide()
              }).catch(err => {
                this.$message.error("操作失败，请重试！")
                this.loadingHide()
              })
          } else {
            return false;
          }
        });
     
    },
  }
};
</script>
<style lang='less' scoped>
@import "../../styles/index";
.user-detail-wraps {
  .user-detail-left {
    margin-top: 140px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    div {
      display: flex;
      align-items: center;
      padding: 20px 0;
      h3 {
        padding: 0;
        margin: 0;
      }
    }
  }
  .user-detail-right {
    h3 {
      // background-image: radial-gradient(circle,#4f84cf,#0b489e);
      // color: #fff;
      height: 40px;
      line-height: 40px;
      padding-left: 15px;
      position: relative;
      &::before {
        content: '';
        width: 5px;
        height: 21px;
        background: #409EFF;
        position: absolute;
        left: 0px;
        top: 9px;
      }
    }
    .el-form {
      width: 50%;
      padding-top: 20px;
      .el-form-item {
        margin-bottom: 30px;
      }
      .el-date-editor{
        width: 100%;
      }
      .el-button {
        padding: 15px 30px;
      }
    }
  }
}
</style>